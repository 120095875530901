<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title mr-0">
          <h3 class="card-label mr-0">
            {{ data.name }}
            <!--     if data.isActive add metronic label active or not active        -->
            <span :class="data.is_active ? 'badge-success' : 'badge-danger'" class="badge p-2" style="font-size: 75%">
              {{ data.is_active ? $t('active') : $t('inactive') }}
            </span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <b-tabs>
          <b-tab :title="$t('subscription_info')" @click="toggleTab('laundry_subscription_view')">
            <div class="p-3">
              <div class="row mt-2">
                <div class="col-6">
                  <p><b>{{ $t('customer_view.name') }}</b>: {{ data.name }}</p>
                </div>
                <div class="col-6">
                  <p><b>{{ $t('max_customers') }}</b> : {{ data.max_customers || 0 }}</p>
                </div>
                <div class="col-6">
                  <p><b>{{ $t('currency') }}</b>: {{ data.currency_name || $t('undefined') }}</p>
                </div>
                <div class="col-6">
                  <p><b>{{ $t('categories.price') }}</b>: {{ data.price || 0 }}</p>
                </div>
                <div class="col-6">
                  <p><b>{{ $t('sales_invoices.start_date') }}</b>: {{ data.start_date || $t('undefined') }}</p>
                </div>
                <div class="col-6">
                  <p><b>{{ $t('sales_invoices.end_date') }}</b>: {{ data.end_date || $t('undefined') }}</p>
                </div>
                <div class="col-6">
                  <p><b>{{ $t('descriptions') }}</b>: {{ data.descriptions || $t('undefined') }}</p>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12">
                  <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                    <h6 class="my-auto text-white">{{ $t('categories.services') }}</h6>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-row-bordered">
                      <thead>
                      <tr>
                        <th>{{ $t('purchases_invoices.items') }}</th>
                        <th>{{ $t('times_no') }}</th>
                        <th>{{ $t('categories.service') }}</th>
                      </tr>

                      </thead>
                      <tbody>
                      <template v-if="data.service_list.length > 0">
                        <tr v-for="(row, index) in data.service_list" :key="index">

                          <td>
                            <span v-if="row.items_object">
                              <span v-for="(item, idx) in row.items_object" :key="'item'+idx">
                                {{ item.name }} <span v-if="idx < row.items_object.length - 1">,</span>
                              </span>
                            </span>
                          </td>
                          <td>{{ row.times || 0 }}</td>
                          <td>{{ row.service.name || $t('undefined') }}</td>

                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="4">{{ $t('no_services') }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('subscripers')" @click="toggleTab('laundry_subscription_customers')">
            <div class="p-3">
              <v-server-table v-if="tab_name === 'laundry_subscription_customers'" ref="table" :class="'dataTable table-row-dashed'" :columns="columns" :options="options">
<!--                <template slot="payment_method" slot-scope="props">-->
<!--                  {{ $t('payment_method'+ props.row.payment_method) }}-->
<!--                </template>-->
              </v-server-table>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "View-Laundry-Subscription",
  data() {
    return {
      mainRoute: 'pos/laundry_subscriptions',
      id: this.$route.params.id ? this.$route.params.id : null,
      columns: ['id', 'customer_name', 'payment_method_name', 'price'],
      tab_name: 'laundry_subscription_view',
      data: {
        name: null,
        max_customers: null,
        currency_name: null,
        price: null,
        start_date: null,
        end_date: null,
        is_active: true,
        descriptions: null,
        service_list: [],
      },
    };
  },
  computed:{
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          id: that.$t('expenses.id'),
          customer_name: that.$t('expenses.name'),
          payment_method_name: that.$t('payment_method'),
          price: that.$t('price'),
        },
        sortable: [],
        filterByColumn: false,
        filterable: false,
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`pos/laundry_subscription/customers_subscription/${that.id}`, {..._params});
        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }
    }
  },
  methods: {
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    async getData() {
      if (this.id)
        ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
          this.data = response.data.data;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.laundry_subscriptions"),
      route: '/pos/laundry-subscriptions'
    }, {title: this.$t('view')}]);

    if (this.id) {
      this.getData();
    }

  },
}
</script>

<style scoped>

</style>
